import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "seek-help-if-needed"
    }}>{`Seek help if needed`}</h2>
    <p>{`Remember to seek professional support for yourself and/or your teen if needed. `}<br /></p>
    <p>{`Consider seeking mental health support if:`}</p>
    <ul>
      <li parentName="ul">{`Your teen has major difficulties adjusting to challenges of the pandemic.`}</li>
      <li parentName="ul">{`You are struggling with your own mental health.`}</li>
    </ul>
    <p>{`By seeking support for yourself when needed, you are also setting a good example for your teen.`}</p>
    {
      /* color each module name */
    }
    <p>{`If you’d like to learn more about supporting your teen during the pandemic, these PiP modules also have some COVID-19 information:`}</p>
    <ul>
      <li parentName="ul">
        <GoTo to="/dashboard" mdxType="GoTo">Connect</GoTo> - includes supporting your teen emotionally during the pandemic
      </li>
      <li parentName="ul">
        <GoTo to="/dashboard" mdxType="GoTo">Calm versus conflict</GoTo> - includes coping with increased family conflict during the pandemic
      </li>
      <li parentName="ul">
        <GoTo to="/dashboard" mdxType="GoTo">Good health habits for good mental health</GoTo> - includes screen time, physical activity, nutrition,
and sleep.
      </li>
    </ul>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m10/45.svg" alt="Child Talking to Professional" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      